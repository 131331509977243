<template>
  <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="财产和行为税纳税申报表">
      <mainTable  ref="maintable"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="印花税(附表)">
      <fbOne ref="fbOne"></fbOne>
    </el-tab-pane>
    <el-tab-pane label="城镇土地使用税(附表)">
      <fbTwo ref="fbTwo"></fbTwo>
    </el-tab-pane>
    <el-tab-pane label="房产税(附表)">
      <fbThree ref="fbThree"></fbThree>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mainTable from "./components/mainTable.vue"
import fbTwo from "./components/fb2.vue"
import fbThree from "./components/fb3.vue"
import fbOne from "./components/fb1.vue"
export default {
  name:'propertyTax',
  components:{
    mainTable,
    fbOne,
    fbTwo,
    fbThree
  },
  data() {
    return {

    }
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '财产和行为税纳税申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '印花税(附表)'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '城镇土地使用税(附表)'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '房产税(附表)'){
        this.$refs.fbThree.getList()
      }
    }
  }
}
</script>

<style>

</style>